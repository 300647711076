import React from "react";
import { useEffectOnce } from "react-use";

import { Helmet } from "react-helmet";
import IndexPage from "./index";

const redirectToApp = () => {
  if (typeof window === "undefined" || !localStorage) {
    return;
  }

  const isAndroidOrIOS =
    /ipad|iphone|ipod|android/.test(navigator.userAgent.toLowerCase()) &&
    !window.MSStream;

  if (!isAndroidOrIOS) {
    var pathnameModified = window.location.pathname.replace("/app", "/web/#");
    window.location.replace(pathnameModified);
  }
};

const AppPage = () => {
  useEffectOnce(() => {
    redirectToApp();
  });

  return (
    <>
      <Helmet
        meta={[
          {
            name: "apple-itunes-app",
            content: "app-id=4Z6E862WH7.com.ted.B2CMasterclass",
            "app-argument":
              typeof window !== "undefined" &&
              document.location.href.split(/[?#]/)[0],
          },
        ]}
      />
      <IndexPage />
    </>
  );
};

export default AppPage;
